import React from "react"
import style from "./intro.mod.scss"
import BorderWrapper from "../border-wrapper/border-wrapper"
import Container from "../container/container"
import { GatsbyImage } from "gatsby-plugin-image"
import Breadcrumbs from "../global/breadcrumbs"
import FeaturedWine from "../featured-wine/featured-wine"

const Intro = ({ children, title, image, description, breadcrumbs, items, light }) => {
  return (
    <div>
      <BorderWrapper topColor={"light"} bottomColor={light && 'light'}>
        {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
        <div className={style.intro__bg}>
          <div className={style.intro}>
            <div className={style.intro__image}>
              {image && (
                <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
              )}
            </div>
            <Container textAlign={"center"} pt={2} pb={2} width={"small"}>
              <div>
                <h1>{title}</h1>
                {description && <p>{description}</p>}
                {children}
              </div>
            </Container>
          </div>
        </div>
      </BorderWrapper>
      {(items && items.length === 2) && <div className={style.intro__bottom}>
        <Container pt={1} pb={2} gutters width={"medium"}>
          <div className={style.intro__cards}>
            {items.map((item) => (
              <FeaturedWine {...item} key={`featured${item.portalId}`} />
            ))}
          </div>
        </Container>
      </div>}
    </div>
  )
}

export default Intro
